.messages-content{
  margin-top: 24px;
}

.order-item{
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
}

.sendbird-app{
  margin-top: 0;
  margin-bottom: 16px;
  height: 88vh;
  max-height: 88vh;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
}

.sendbird-chat-desktop {

  height: inherit;
  /* max-height: 90vh; */
  display: grid;
  grid-template-columns: 320px 1fr;
}

.sendbird-chat-desktop__channel-list {
  grid-column: 1;
}

.sendbird-chat-desktop__channel {
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
}

.sendbird-chat-desktop__channel-settings,
.sendbird-chat-desktop__channel-message-search {
  grid-column: 2;
  width: 320px;
}

.sendbird-chat-desktop__channel-list,
.sendbird-chat-desktop__channel,
.sendbird-chat-desktop__channel-settings,
.sendbird-chat-desktop__channel-message-search {
  height: 100%;
}

.sendbird-channel-list .sendbird-channel-header .sendbird-channel-header__right-icon{
  display: none;
}

.messages-container{
  margin-top: 24px;
  margin-bottom: 16px;
  width: 95%;
  display: flex;
  height: calc(90vh - 20px);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
}

.conversation-items{
  width: 100%;
}

.sendbird-channel-preview .sendbird-channel-preview__action .sendbird-iconbutton{
  display: none !important;
}

.sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:hover{
  background: unset !important;
  pointer-events: none;
}
.sendbird-channel-preview:hover .sendbird-channel-preview__content__upper__last-message-at{
  display: block !important;
}

.sendbird-conversation{
  height: inherit !important;
}

.sendbird-channel-header.sendbird-channel-header--allow-edit .sendbird-channel-header__title{
  cursor: unset !important;
  pointer-events: none !important;
}

.sendbird-channel-header__title{
  pointer-events: none;
}

.sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:active{
  border: none !important;
}

.sendbird-channel-list {
  border-bottom: 1px solid #ccc;
}